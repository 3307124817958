// HUNGARY
export const locale = {
  TRANSLATOR: {
    SELECT: "Válasszon nyelvet",
  },
  MENU: {
    NEW: "Új",
    ACTIONS: "Műveletek",
    CREATE_POST: "Új bejegyzés",
    PAGE_ADMINISTARATION: "Oldalak kezelése",
    NEWS: "Hírek",
    NEWS_CATEGORIES: "Kategóriák",
    NEWS_CATEGORY: "Kategória",
    PUBLICATIONS: "Kiadványok",
    PUBLICATION: "Kiadvány",
    PUBLICATION_CATEGORIES: "Kategóriák",
    PUBLICATION_CATEGORY: "Kategória",
    PAGES: "Oldalak",
    PAGE: "Oldal",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    MEDIA_LIBRARY: "Médiatár",
    QUOTATIONS: "Ajánlások kezelése",
    QUOTATION_CATEGORY: "Ajánlás kategória",
    QUOTATION_CATEGORIES: "Ajánlás kategóriák",
    LOGO: "Logó",
    LOGOS: "Logók",
    EXTERNAL_REFERENCE: "Külső hivatkozás",
    EXTERNAL_REFERENCES: "Külső hivatkozások",
    COLLEAGUE: "Oktató",
    COLLEAGUES: "Oktatók",
    COLLEAGUES_ADMINISTRATION: "Oktatók kezelése",
    COLLEAGUE_CATEGORY: "Oktató kategória",
    COLLEAGUE_CATEGORIES: "Oktató kategóriák",
    QUOTATION: "Ajánlások kezelése",
    COOKIE_CATEGORIES: "Süti kategóriák",
    COOKIE_CATEGORY: "Süti kategória",
    COOKIE_MANAGEMENT: "Sütikezelés",
    COOKIE: "Süti",
    COOKIES: "Sütik",
    SETTINGS: "Beállítások",
    PAGE_TEMPLATE: "Oldal sablon",
    PAGE_TEMPLATES: "Oldal sablonok",
    TAG: "Címke",
    TAGS: "Címkék",
    PAGE_SETTINGS: "Weboldal beállítások",
    MENUS: "Menük kezelése",
    BANNERS: "Bannerek kezelése",
    FAQ: "Gyakran ismételt kérdések",
    INSTRUCTOR: "Előadó",

    systemSettings: "Rendszer beállítások",
    users: "Felhasználók",
    userGroups: "Felhasználói csoportok",
    roles: "Jogosultságok",
    currencies: "Pénznemek",
    countries: "Országok",
    companySettings: "Vállalati beállítások",
    departments: "Részlegek",
    sites: "Weboldalak",
    banners: "Bannerek",
    banner: "Banner",
    contact_form: "Űrlapok",
    BLOG_CATEGORIES: "Kategóriák",
    BLOG_CATEGORY: "Kategória",
    BLOGS: "Blog",
    POSTS: "Bejegyzések",
    POST: "Bejegyzés",
    quotations: "Ajánlások",
    quotation: "Ajánlás",
    bannerTypes: "Banner típusok",
    bannerType: "Banner típus",
    GALLERIES: "Galériák",
    GALLERY: "Galéria",
  },
  ALERT: {
    choose_only_maxFiles: "Maximum {maxFiles} fájlt választhat!",
    file_info_title: "Fájl információk",
    created_at: "Létrehozva",
    name: "Neve",
    mime_type: "Típusa",
    path: "Elérés",
    size: "Méret",
    width: "Szélesség",
    height: "Magasság",
    pixel: "Képpont",
  },
  CONTACT_FORM: {
    contact_info: "Űrlap részletei",
    company_name: "Cégnév",
    created_at: "Létrehozva",
    first_name: "Keresztnév",
    last_name: "Vezetéknév",
    locale: "Nyelv",
    message: "Üzenet",
    phone: "Telefonszám",
    remote_ip: "Távoli IP cím",
    user_agent: "User agent",
    email: "E-mail",
  },
  AUTH: {
    WELCOME: "Üdvözöl a SYNERGY fox",
    HI: "Szia",
    USER_PROFILE: "Profil adatok",
    SIGN_OUT_BUTTON: "Kilépés",
    GENERAL: {
      OR: "Vagy",
      SUBMIT_BUTTON: "Beküldés",
      NO_ACCOUNT: "Nincs még felhasználói fiókja?",
      SIGNUP_BUTTON: "Regisztráció",
      FORGOT_BUTTON: "Elfelejtett jelszó",
      BACK_BUTTON: "Vissza",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Kapcsolat",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Belépés",
      SIGN_IN: "Belépés",
      TEXT: "Írd be az e-mail címedet és a jelszavadat",
    },
    FORGOT: {
      TITLE: "Elfelejtette jelszavát?",
      DESC: "Adja meg email címét a jelszó helyreállításához",
      SUCCESS: "Fiókját sikeresen visszaállítottuk.",
    },
    REGISTER: {
      TITLE: "Regisztráció",
      DESC: "A fiók létrehozásához adja meg adatait",
      SUCCESS: "Fiókját sikeresen létrehoztuk",
    },
    INPUT: {
      EMAIL: "Email cím",
      FULLNAME: "Név",
      PASSWORD: "Jelszó",
      CONFIRM_PASSWORD: "Jelszó megerősítése",
      USERNAME: "Felhasználónév",
    },
    VALIDATION: {
      INVALID: "{{name}} érvénytelen",
      REQUIRED: "{{name}} megadása kötelező",
      MIN_LENGTH: "{{name}} minimum {{min}} karakter kell legyen",
      AGREEMENT_REQUIRED: "A felhasználási feltételek elfogadása kötelező",
      NOT_FOUND: "A kért {{name}} nem található",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Kötelező mező",
      MIN_LENGTH_FIELD: "Minimális karakterszám:",
      MAX_LENGTH_FIELD: "Maximális karakterszám:",
      INVALID_FIELD: "Érvénytelen mező",
      // "Invalid Email or Password": "Invalid Email or Password",
      token_expired: "A token lejárt, ezért kiléptettük",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },
  PERMISSIONS: {
    module_pages_menu: "Oldalak modul",
    module_contactForms_menu: "Űrlapok modul",
    module_quotations_menu: "Ajánlások modul",
    module_blog_menu: "Blog modul",
    module_logos_menu: "Logó modul",
    module_colleagues_menu: "Oktatók modul",
    module_gdpr_menu: "Beállítások modul",
    module_news_menu: "Hírek modul",
    module_external_references_menu: "Külső hivatkozások modul",
    module_galleries: "Galériák modul",
    module_publication_menu: "Kiadványok modul",
    module_faq_menu: "Gyakran ismételt kérdések modul",
    module_instructors_menu: "Gyakran ismételt kérdések modul",

    module_galleries_menu: "Galériák modul",

    external_references_setting_create:
      "Külső hivatkozások - Külső hivatkozás beállítás létrehozása",
    external_references_setting_delete:
      "Külső hivatkozások - Külső hivatkozás beállítás törlése",
    external_references_setting_view:
      "Külső hivatkozások - Külső hivatkozás beállítás megtekintése",
    external_references_setting_update:
      "Külső hivatkozások - Külső hivatkozás beállítás szerkesztése",
    external_references_setting_menu:
      "Külső hivatkozások - Külső hivatkozás beállítások menü",

    system_setting_menu: "Rendszer beállítások menü",
    system_setting_update:
      "Rendszer beállítások - Rendszer beállítások szerkesztése",
    system_setting_view:
      "Rendszer beállítások - Rendszer beállítások megtekintése",
    system_user_menu: "Rendszer beállítások - Felhasználók menü",
    system_user_create: "Rendszer beállítások - Felhasználó létrehozása",
    system_user_delete: "Rendszer beállítások - Felhasználó törlése",
    system_user_update: "Rendszer beállítások - Felhasználó szerkesztése",
    system_user_update_self:
      "Rendszer beállítások - Saját felhasználó szerkesztése",
    system_user_view: "Rendszer beállítások - Felhasználó megtekintése",
    system_user_view_self:
      "Rendszer beállítások - Saját felhasználó megtekintése",
    system_userGroup_menu: "Rendszer beállítások - Felhasználói csoportok menü",
    system_userGroup_create:
      "Rendszer beállítások - Felhasználói csoport létrehozása",
    system_userGroup_delete:
      "Rendszer beállítások - Felhasználói csoport törlése",
    system_userGroup_update:
      "Rendszer beállítások - Felhasználói csoport szerkesztése",
    system_userGroup_update_self:
      "Rendszer beállítások - Saját felhasználói csoport szerkesztése",
    system_userGroup_view:
      "Rendszer beállítások - Felhasználói csoport megtekintése",
    system_userGroup_view_self:
      "Rendszer beállítások - Saját felhasználói csoport megtekintése",
    system_role_menu: "Rendszer beállítások - Jogosultságok menü",
    system_role_create: "Rendszer beállítások - Jogosultság létrehozása",
    system_role_delete: "Rendszer beállítások - Jogosultság törlése",
    system_role_update: "Rendszer beállítások - Jogosultság  szerkesztése",
    system_role_view: "Rendszer beállítások - Jogosultság megtekintése",
    system_country_menu: "Rendszer beállítások - Országok menü",
    system_country_update: "Rendszer beállítások - Ország szerkesztése",
    system_country_view: "Rendszer beállítások - Ország megtekintése",
    system_currency_menu: "Rendszer beállítások - Pénznemek menü",
    system_currency_create: "Rendszer beállítások - Pénznem létrehozása",
    system_currency_delete: "Rendszer beállítások - Pénznem törlése",
    system_currency_update: "Rendszer beállítások - Pénznem szerkesztése",
    system_currency_view: "Rendszer beállítások - Pénznem megtekintése",

    companySettings_department_menu: "Vállalati beállítások - Részlegek menü",
    companySettings_department_create:
      "Vállalati beállítások - Részleg létrehozása",
    companySettings_department_delete:
      "Vállalati beállítások - Részleg törlése",
    companySettings_department_update:
      "Vállalati beállítások - Részleg szerkesztése",
    companySettings_department_view:
      "Vállalati beállítások - Részleg megtekintése",
    companySettings_site_menu: "Vállalati beállítások - Telephelyek menü",
    companySettings_site_create:
      "Vállalati beállítások - Telephely létrehozása",
    companySettings_site_delete: "Vállalati beállítások - Telephely törlése",
    companySettings_site_update:
      "Vállalati beállítások - Telephely szerkesztése",
    companySettings_site_view: "Vállalati beállítások - Telephely megtekintése",

    pages_page_create: "Oldalak - Oldal létrehozása",
    pages_page_delete: "Oldalak - Oldal törlése",
    pages_page_view: "Oldalak - Oldal megtekintése",
    pages_page_update: "Oldalak - Oldal szerkesztése",
    pages_page_menu: "Oldalak - Oldalak menü",

    blog_tag_create: "Blog - Címke létrehozása",
    blog_tag_delete: "Blog - Címke törlése",
    blog_tag_view: "Blog - Címke megtekintése",
    blog_tag_update: "Blog - Címke szerkesztése",
    blog_tag_menu: "Blog - Címke menü",

    news_tag_create: "Hírek - Címke létrehozása",
    news_tag_delete: "Hírek - Címke törlése",
    news_tag_view: "Hírek - Címke megtekintése",
    news_tag_update: "Hírek - Címke szerkesztése",
    news_tag_menu: "Hírek - Címke menü",

    pages_menu_create: "Oldalak - Menü létrehozása",
    pages_menu_delete: "Oldalak - Menü törlése",
    pages_menu_view: "Oldalak - Menü megtekintése",
    pages_menu_update: "Oldalak - Menü szerkesztése",
    pages_menu_menu: "Oldalak - Menük menü",

    pages_banner_create: "Oldalak - Banner létrehozása",
    pages_banner_delete: "Oldalak - Banner törlése",
    pages_banner_view: "Oldalak - Banner megtekintése",
    pages_banner_update: "Oldalak - Banner szerkesztése",
    pages_banner_menu: "Oldalak - Bannerek menü",

    contactForms_contactForm_create: "Űrlapok - Űrlap létrehozása",
    contactForms_contactForm_delete: "Űrlapok - Űrlap törlése",
    contactForms_contactForm_view: "Űrlapok - Űrlap megtekintése",
    contactForms_contactForm_update: "Űrlapok - Űrlap szerkesztése",
    contactForms_contactForm_menu: "Űrlapok - Űrlapok menü",

    quotations_quotation_create: "Ajánlások - Ajánlás létrehozása",
    quotations_quotation_delete: "Ajánlások - Ajánlás törlése",
    quotations_quotation_view: "Ajánlások - Ajánlás megtekintése",
    quotations_quotation_update: "Ajánlások - Ajánlás szerkesztése",
    quotations_quotation_menu: "Ajánlások - Ajánlások menü",

    quotations_quotationCategory_create:
      "Ajánlások - Ajánlás kategória létrehozása",
    quotations_quotationCategory_delete:
      "Ajánlások - Ajánlás kategória törlése",
    quotations_quotationCategory_view:
      "Ajánlások - Ajánlás kategória megtekintése",
    quotations_quotationCategory_update:
      "Ajánlások - Ajánlás kategória szerkesztése",
    quotations_quotationCategory_menu: "Ajánlások - Ajánlás kategóriák menü",

    blog_category_create: "Blog - Kategória létrehozása",
    blog_category_delete: "Blog - Kategória törlése",
    blog_category_view: "Blog - Kategória megtekintése",
    blog_category_update: "Blog - Kategória szerkesztése",
    blog_category_menu: "Blog - Kategória menü",

    faq: "Gyakran ismételt kérdések",
    faq_category_create: "Gyakran ismételt kérdések  - Kategória létrehozása",
    faq_category_delete: "Gyakran ismételt kérdések  - Kategória törlése",
    faq_category_view: "Gyakran ismételt kérdések  - Kategória megtekintése",
    faq_category_update: "Gyakran ismételt kérdések  - Kategória szerkesztése",
    faq_category_menu: "Gyakran ismételt kérdések  - Kategória menü",

    instructors: "Előadók",
    instructors_instructor_create: "Előadók  - Előadó létrehozása",
    instructors_instructor_delete: "Előadók  - Előadó törlése",
    instructors_instructor_view: "Előadók  - Előadó megtekintése",
    instructors_instructor_update: "Előadók  - Előadó szerkesztése",
    instructors_instructor_menu: "Előadók  - Előadó menü",

    instructors_setting_create: "Előadók - Beállítás létrehozása",
    instructors_setting_delete: "Előadók - Beállítás törlése",
    instructors_setting_view: "Előadók - Beállítás megtekintése",
    instructors_setting_update: "Előadók - Beállítás szerkesztése",
    instructors_setting_menu: "Előadók - Beállítások menü",

    blog_post_create: "Blog - Bejegyzés létrehozása",
    blog_post_delete: "Blog - Bejegyzés törlése",
    blog_post_view: "Blog - Bejegyzés megtekintése",
    blog_post_update: "Blog - Bejegyzés szerkesztése",
    blog_post_menu: "Blog - Bejegyzések menü",

    blog_setting_create: "Blog - Beállítás létrehozása",
    blog_setting_delete: "Blog - Beállítás törlése",
    blog_setting_view: "Blog - Beállítás megtekintése",
    blog_setting_update: "Blog - Beállítás szerkesztése",
    blog_setting_menu: "Blog - Beállítások menü",

    news_category_create: "Hírek - Kategória létrehozása",
    news_category_delete: "Hírek - Kategória törlése",
    news_category_view: "Hírek - Kategória megtekintése",
    news_category_update: "Hírek - Kategória szerkesztése",
    news_category_menu: "Hírek - Kategória menü",

    news_news_create: "Hírek - Bejegyzés létrehozása",
    news_news_delete: "Hírek - Bejegyzés törlése",
    news_news_view: "Hírek - Bejegyzés megtekintése",
    news_news_update: "Hírek - Bejegyzés szerkesztése",
    news_news_menu: "Hírek - Bejegyzések menü",

    publication_category_create: "Kiadványok - Kategória létrehozása",
    publication_category_delete: "Kiadványok - Kategória törlése",
    publication_category_view: "Kiadványok - Kategória megtekintése",
    publication_category_update: "Kiadványok - Kategória szerkesztése",
    publication_category_menu: "Kiadványok - Kategória menü",

    publication_post_create: "Kiadványok - Bejegyzés létrehozása",
    publication_post_delete: "Kiadványok - Bejegyzés törlése",
    publication_post_view: "Kiadványok - Bejegyzés megtekintése",
    publication_post_update: "Kiadványok - Bejegyzés szerkesztése",
    publication_post_menu: "Kiadványok - Bejegyzések menü",

    publication_setting_create: "Kiadványok - Beállítás létrehozása",
    publication_setting_delete: "Kiadványok - Beállítás törlése",
    publication_setting_view: "Kiadványok - Beállítás megtekintése",
    publication_setting_update: "Kiadványok - Beállítás szerkesztése",
    publication_setting_menu: "Kiadványok - Beállítás menü",

    news_setting_create: "Hírek - Beállítás létrehozása",
    news_setting_delete: "Hírek - Beállítás törlése",
    news_setting_view: "Hírek - Beállítás megtekintése",
    news_setting_update: "Hírek - Beállítás szerkesztése",
    news_setting_menu: "Hírek - Beállítások menü",

    pages_bannerType_create: "Oldalak - Banner típus létrehozása",
    pages_bannerType_delete: "Oldalak - Banner típus törlése",
    pages_bannerType_view: "Oldalak - Banner típus megtekintése",
    pages_bannerType_update: "Oldalak - Banner típus szerkesztése",
    pages_bannerType_menu: "Oldalak - Banner típusok menü",

    logos_category_create: "Logó - Logó létrehozása",
    logos_category_delete: "Logó - Logó törlése",
    logos_category_view: "Logó - Logó megtekintése",
    logos_category_update: "Logó - Logó szerkesztése",
    logos_category_menu: "Logó - Logók menü",

    external_references_category_create:
      "Külső hivatkozások - Külső hivatkozás létrehozása",
    external_references_category_delete:
      "Külső hivatkozások - Külső hivatkozás törlése",
    external_references_category_view:
      "Külső hivatkozások - Külső hivatkozás megtekintése",
    external_references_category_update:
      "Külső hivatkozások - Külső hivatkozás szerkesztése",
    external_references_category_menu:
      "Külső hivatkozások - Külső hivatkozások menü",

    colleagues_colleagueCategory_create:
      "Oktatók - Oktató kategória létrehozása",
    colleagues_colleagueCategory_delete: "Oktatók - Oktató kategória törlése",
    colleagues_colleagueCategory_view:
      "Oktatók - Oktató kategória megtekintése",
    colleagues_colleagueCategory_update:
      "Oktatók - Oktató kategória szerkesztése",
    colleagues_colleagueCategory_menu: "Oktatók - Oktató kategóriák menü",

    colleagues_colleague_create: "Oktatók - Oktató létrehozása",
    colleagues_colleague_delete: "Oktatók - Oktató törlése",
    colleagues_colleague_view: "Oktatók - Oktató megtekintése",
    colleagues_colleague_update: "Oktatók - Oktató szerkesztése",
    colleagues_colleague_menu: "Oktatók - Oktatók menü",

    colleagues_setting_create: "Oktatók - Beállítás létrehozása",
    colleagues_setting_delete: "Oktatók - Beállítás törlése",
    colleagues_setting_view: "Oktatók - Beállítás megtekintése",
    colleagues_setting_update: "Oktatók - Beállítás szerkesztése",
    colleagues_setting_menu: "Oktatók - Beállítások menü",

    pages_pageTemplate_create: "Oldalak - Oldal sablon létrehozása",
    pages_pageTemplate_delete: "Oldalak - Oldal sablon törlése",
    pages_pageTemplate_view: "Oldalak - Oldal sablon megtekintése",
    pages_pageTemplate_update: "Oldalak - Oldal sablon szerkesztése",
    pages_pageTemplate_menu: "Oldalak - Oldal sablonok menü",

    gdpr_category_create: "Beállítások - Süti kategória létrehozása",
    gdpr_category_delete: "Beállítások - Süti kategória törlése",
    gdpr_category_view: "Beállítások - Süti kategória megtekintése",
    gdpr_category_update: "Beállítások - Süti kategória szerkesztése",
    gdpr_category_menu: "Beállítások - Süti kategóriák menü",

    gdpr_code_create: "Beállítások - Süti létrehozása",
    gdpr_code_delete: "Beállítások - Süti törlése",
    gdpr_code_view: "Beállítások - Süti megtekintése",
    gdpr_code_update: "Beállítások - Süti szerkesztése",
    gdpr_code_menu: "Beállítások - Sütik menü",

    system_site_view: "Beállítások - Weboldal beállítások megtekintése",
    system_site_update: "Beállítások - Weboldal beállítások szerkesztése",
    system_site_menu: "Beállítások - Weboldal beállítások menü",
    system_mediaLibrary_menu: "Médiatár menü",

    galleries_category_create: "Galériák - Galéria létrehozása",
    galleries_category_delete: "Galériák - Galéria törlése",
    galleries_category_view: "Galériák - Galéria megtekintése",
    galleries_category_update: "Galériák - Galéria szerkesztése",
    galleries_category_menu: "Galériák - Galériák menü",

    galleries_setting_create: "Galériák - Beállítás létrehozása",
    galleries_setting_delete: "Galériák - Beállítás törlése",
    galleries_setting_view: "Galériák - Beállítás megtekintése",
    galleries_setting_update: "Galériák - Beállítás szerkesztése",
    galleries_setting_menu: "Galériák - Beállítások menü",

    faq_setting_create: "Gyakran ismételt kérdések - Beállítás létrehozása",
    faq_setting_delete: "Gyakran ismételt kérdések - Beállítás törlése",
    faq_setting_view: "Gyakran ismételt kérdések - Beállítás megtekintése",
    faq_setting_update: "Gyakran ismételt kérdések - Beállítás szerkesztése",
    faq_setting_menu: "Gyakran ismételt kérdések - Beállítások menü",

    system: "Rendszer beállítások",
    companySettings: "Vállalati beállítások",
    module: "Modulok",
    pages: "Oldalak",
    contactForms: "Űrlapok",
    quotations: "Ajánlások",
    blog: "Blog",
    news: "Hírek",
    logos: "Logók",
    colleagues: "Oktatók",
    gdpr: "Beállítások",
    external_references: "Külső hivatkozások",
    galleries: "Galériák",
    publication: "Kiadványok",
  },

  FORMS: {
    new: "Új",
    menu: "Menü",
    edit_menu: "Menük kezelése",
    menus: "Menük kezelése",
    cancel: "Mégse",
    save: "Mentés",
    status: "Státusz",
    name: "Név",
    label: "Cím",
    parent: "Szülő",
    icon: "Ikon",
    target: "Cél",
    slug: "Slug",
    active: "Aktív",
    inactive: "Inaktív",
    are_you: "Valóban törölni szeretnéd a kiválasztott elemet",
    edit: "Szerkesztés - ",
    editPage: "Oldal szerkesztése",
    pageName: "Oldal neve",
    new_role: "Új szerepkör",
    excel_export: "Export xls",
    excel_exporting: "Exportálás excel fájlba",
    description: "Leírás",
    short_description: "Rövid leírás",
    shared_files: "Gyökér könyvtár",
    countries: "Országok",
    upload: "Feltölt",

    search: "Keresés",
    user: "Felhasználó",
    users: "Felhasználók",
    userGroup: "Felhasználói csoport",
    userGroups: "Felhasználói csoportok",
    roles: "Jogosultságok",
    sites: "Weboldalak",
    choose_site: "Válasszon weboldalt",

    firstName: "Vezetéknév",
    middleName: "Középső név",
    lastName: "Keresztnév",
    language: "Nyelv",
    password: "Jelszó",
    userGroupName: "Felhasználói csoport neve",
    select: "Kiválaszt",
    contact_form: "Űrlapok",
    close: "Bezár",
    text: "Szöveg",
    intro: "Bevezető szöveg",
    position: "Pozíció",
    email: "E-mail",
    phone: "Telefonszám",
    seo_title: "SEO cím",
    seo_description: "SEO leírás",
    companyName: "Cég neve",
    select_users: "Válassz felhasználót",
    newsletter: "Hírlevél",
    country: "Ország",
    id: "Azonosító",
    currency: "Pénznem",
    sign: "Pénzjel",
    exchange_rate: "Árfolyam",
    format: "Formátum",
    permissions: "Jogosultságok",
    active_countries: "Aktivált országok",
    login: "Belépés",
    fields: "Mezők",
    system_settings: "Rendszer beállítások",
    new_currency: "Új pénznem",
    currencies: "Pénznemek",
    actions: "Műveletek",
    select_site_language_order: "Az oldal nyelvei",
    content_language: "Tartalom nyelve",
    system_language: "A rendszer nyelve",
    date_of_created_at: "Létrehozás dátuma",
    time_of_created_at: "Létrehozás ideje",
    add: "Hozzáad",
    address: "Cím",
    post_address: "Postacím",
    fax: "Fax",
    gMapURL: "Google Maps URL",
    accordion: "Accordion",
    accordionQuestion: "Kérdés",
    accordionAnswer: "Válasz",
  },

  SALESORDERSSTATUSES: {
    new: "New",
    waiting_for_offer: "Waiting for offer",
    offer_approval: "Offer approval",
    offer_done: "Offer done",
    order_approval: "Order approval",
    ordered: "Ordered",
    contracted: "Contracted",
    to_be_produced: "To be produced",
    in_production: "In production",
    to_be_delivered: "To be delivered",
    delivered: "Delivered",
    closed: "Closed",
    revoked: "Revoked",
  },

  FORM_INPUT_NAMES: {
    total_net: "Total net",
    total_gross: "Total gross",
    owner_id: "Owner",
    paid: "Paid",
    lost: "Lost",
    source_type_id: "Source type",
    locale: "Locale",
    customer: "Customer",
    billingAddress: "Billing address",
    shippingAddress: "Shipping address",
    expiration_date: "Expiration date",
    delivery_date: "Delivery date",
    delivery_days: "Delivery days",
    price_list_id: "Price list",
    discount_value: "Discount value",
    discountNetValue: "Discount net value",
    notes: "Comment",
    status: "Status",
    id: "Id",
    sent: "Sent",
    zero_tax: "Zero tax",
    addresses: "Adresses",
    price_net: "Price net",
    so_number: "SO number",
    approved: "Approved",
    company_id: "Company Id",
    contact_id: "Contact Id",
    attachments: "Attachments",
    old_version: "Old version",
    price_gross: "Price gross",
    discount_type: "Discount type",
    sender_name: "Feladó neve",
    sender_email: "Feladó email címe",
    languages: "Nyelvek",
    select_logo: "Válassz logót",
    select_icon: "Válassz ikont",
    select_image: "Válassz képet",
    select_favicon: "Válassz favicon-t",
    select_languages: "Válassz nyelveket",
    select_public_languages: "Válassz publikus nyelveket",
    alt_text: "Helyettesítő szöveg",
    title: "Cím",
    description: "Leírás",
    url: "URL",
    youtube_url: "YouTube URL",
    gtm: "GTM",
    recaptcha_site_key: "reCAPTCHA site key",
    recaptcha_site_secret: "reCAPTCHA site secret",
    button_text: "Gomb szövege",
    text1: "Cím",
    text2: "Alcím",
    name: "Neve",
    type: "Típus",
    desktop_image: "Asztali kép",
    mobile_image: "Mobil kép",
    galery: "Galéria",
    tags: "Címkék",
    featured_image: "Kiemelt kép",
    card_image: "Kártyakép",
    placement: "Elhelyezés",
    category: "Kategória",
    select_range: "Válassz intervallumot",
    og_site_name: "OG oldal neve",
    og_title: "OG oldal címe",
    og_type: "OG típusa",
    og_url: "OG URL",
    og_description: "OG leírása",
    og_image: "OG kép feltöltése",
    related_posts: "Rögzített bejegyzés ajánló",
    related_news: "Rögzített hír ajánló",
    select_ExternalReference: "Külső hivatkozás",
    url_prefix: "URL előtag",
    icon: "Ikon",
    gallery: "Galéria",
    site_title: "Az intézmény neve",
    select_publication_item: "Válassz elemet",
    year: "Év",
    yearNum: "Évszám",
    gMapURL: "Google térkép URL",
    question: "Kérdés",
    answer: "Válasz",
    publicated: "Publikálva",
    iframe: "IFRAME",
    date: "Dátum",
    subpages: "Aloldalak",
  },
  EMAIL: {
    offer: "Offer2",
  },
  ACTIVITY: {
    not_logged_id: "Customer",
  },

  ADJUSTMENT: {
    status_null: "",
    status_undefined: "",
    status_0: "New",
    status_1: "Under production",
    status_2: "Under procurement",
    status_3: "Remainder",
    status_4: "On hand",
    status_5: "Build in",
    status_6: "Released",
    status_7: "Shipping",
    status_8: "Delivered",
    status_9: "Damaged",
    status_10: "Lost",

    typeOf_1: "Add",
    typeOf_2: "Remove",
    typeOf_3: "Edit",
  },

  ERRORS: {
    file_already_exists: "A fájl már létezik",
    field_is_required: "Kötelező mező",
    must_be_max_characters: "A mező hossz max 250 karakter lehet",
    language_is_required: "A nyelv megadása kötelező",
    currency_is_required: "A pénznem megadása kötelező",
    email_is_required: "Az E-mail cím megadása kötelező",
    email_is_valid: "Az E-mail cím formátuma nem megfelelő",
    site_is_required: "A weboldal megadása kötelező",
    user_group_is_required: "A felhasználó csoport megadása kötelező",
    role_is_required: "A szerepkör megadása kötelező",
    password_is_required: "A jelszó megadása kötelező",
  },

  TABLE: {
    created_at: "Létrehozva",
    name: "Név",
    status: "Státusz",
    actions: "Műveletek",
    type: "Típus",
    page: "Oldal",
    slug: "Slug",
    post: "Bejegyzés",
    blog_category: "Blog kategória",
    news_category: "Hír kategória",
    publication_category: "Kategória",
    created: "Létrehozva",
    phone: "Telefon",
    company: "Vállalat",
    quotationCategory: "Ajánlás kategória",
    logo: "Logó",
    colleagueCategory: "Oktató kategória",
    id: "Azonosító",
    country: "Ország",
    language: "Nyelv",
    currency: "Pénznem",
    eu: "EU",
    are_you_sure_delete: "Valóban törölni szeretnéd a kiválasztott elemet?",
    sign: "Pénzjel",
    exchange_rate: "Árfolyam",
    cannot_delete: "Ez az elem nem törölhető",
    translated: "Fordítás",
    first_name: "Vezetéknév",
    christian_name2: "Középső név",
    last_name: "Keresztnév",
    ok: "Ok",
    cancel: "Mégse",
    yes: "Igen",
    no: "Nem",
    categories: "Kategóriák",
  },

  MEDIA_LIBRARY: {
    create_new_directory: "Új könyvtár",
    rename_directory: "Könyvtár átnevezése",
    rename_file: "Fájl átnevezése",
    file_upload: "Fájlok feltöltése",
    please_select: "Kérem válasszon",
    uploading: "Feltöltés",
    edit_image: "Kép szerkesztése",
    crop_and_save: "Vágás és mentés",
    file_upload_max_size: "Feltölthető max. fájlméret",
    file_upload_max_count: "Maximálisan feltölthető fájlok darabszáma",

    select_media: "Válasszon tartalmat",
  },

  EXCELL: {
    created_at: "Létrehozva",
    first_name: "Vezetéknév",
    last_name: "Keresztnév",
    email: "Email",
    phone: "Telefonszám",
    company_name: "Vállalat",
    message: "Üzenet",
    locale: "Nyelv",
    remote_ip: "Távoli IP cím",
    user_agent: "User agent",
    name: "Név",
  },

  SELECTS: {
    1: "Szöveges + csatolmány",
    2: "Linkesített átirányítás",
    3: "Szöveges bejegyzés",
    4: "PDF",
  },

  BANNERS: {
    BG_OF_BANNER_IS_IMAGE: "Banner kép",
    BG_OF_BANNER_IS_EMBEDDED_VIDEO: "Banner beágyazott videó",
  },
};
