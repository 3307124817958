import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/cms",
          name: "cms",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "menus/",
              name: "menus",
              component: () => import("@/view/pages/cms/menu/Menus.vue"),
            },
            {
              path: "/cms/menus/:id",
              component: () => import("@/view/pages/cms/menu/Menus.vue"),
            },
            //BLOG
            {
              path: "blog/categories/",
              name: "blogCategories",
              component: () =>
                import("@/view/pages/cms/blog/blogCategory/BlogCategories.vue"),
            },

            {
              path: "/cms/blog/categories/:id",
              component: () =>
                import("@/view/pages/cms/blog/blogCategory/BlogCategories.vue"),
            },
            {
              path: "blog/posts/",
              name: "posts",
              component: () => import("@/view/pages/cms/blog/posts/Posts.vue"),
            },
            {
              path: "/cms/blog/posts/:id",
              component: () => import("@/view/pages/cms/blog/posts/Posts.vue"),
            },
            {
              path: "blog/tags/",
              name: "blogTags",
              component: () => import("@/view/pages/cms/blog/tags/Tags.vue"),
            },
            {
              path: "/cms/blog/tags/:id",
              component: () => import("@/view/pages/cms/blog/tags/Tags.vue"),
            },
            {
              path: "blog/settings/",
              name: "blogSettngs",
              component: () => import("@/view/pages/cms/blog/Settings.vue"),
            },

            //END OF BLOG

            //NEWS
            {
              path: "news/categories/",
              name: "newsCategories",
              component: () =>
                import("@/view/pages/cms/news/newsCategory/NewsCategories.vue"),
            },

            {
              path: "/cms/news/categories/:id",
              component: () =>
                import("@/view/pages/cms/news/newsCategory/NewsCategories.vue"),
            },

            {
              path: "news/news/",
              name: "news",
              component: () => import("@/view/pages/cms/news/news/News.vue"),
            },
            {
              path: "/cms/news/news/:id",
              component: () => import("@/view/pages/cms/news/news/News.vue"),
            },

            {
              path: "news/tags/",
              name: "tags",
              component: () => import("@/view/pages/cms/news/tags/Tags.vue"),
            },
            {
              path: "/cms/news/tags/:id",
              component: () => import("@/view/pages/cms/news/tags/Tags.vue"),
            },
            {
              path: "news/settings/",
              name: "newsSettngs",
              component: () => import("@/view/pages/cms/news/Settings.vue"),
            },

            //END OF NEWS
            // PUBLICATIONS
            {
              path: "publications/publicationCategories/",
              name: "publicationCategories",
              component: () =>
                import(
                  "@/view/pages/cms/publications/publicationCategories/PublicationCategories.vue"
                ),
            },

            {
              path: "/cms/publications/publicationCategories/:id",
              component: () =>
                import(
                  "@/view/pages/cms/publications/publicationCategories/PublicationCategories.vue"
                ),
            },

            {
              path: "publications/publications/",
              name: "publications",
              component: () =>
                import(
                  "@/view/pages/cms/publications/publications/Publications.vue"
                ),
            },
            {
              path: "/cms/publications/publications/:id",
              component: () =>
                import(
                  "@/view/pages/cms/publications/publications/Publications.vue"
                ),
            },
            {
              path: "publications/settings/",
              name: "publicationsSettngs",
              component: () =>
                import("@/view/pages/cms/publications/Settings.vue"),
            },
            // end publications
            {
              path: "pages/pages",
              name: "pages",
              component: () => import("@/view/pages/cms/pages/Pages.vue"),
            },
            {
              path: "/cms/pages/pages/:id",
              component: () => import("@/view/pages/cms/pages/Pages.vue"),
            },
            {
              path: "pages/pageTemplates/",
              name: "pageTemplates",
              component: () =>
                import("@/view/pages/cms/pages/PageTemplates.vue"),
            },
            {
              path: "/cms/pages/pageTemplates/:id",
              component: () =>
                import("@/view/pages/cms/pages/PageTemplates.vue"),
            },

            {
              path: "quotations/quotations/",
              name: "quotations",
              component: () =>
                import("@/view/pages/cms/quotations/quotations/Quotations.vue"),
            },
            {
              path: "/cms/quotations/quotations/:id",
              component: () =>
                import("@/view/pages/cms/quotations/quotations/Quotations.vue"),
            },
            {
              path: "quotations/quotationCategories/",
              name: "quotationCategories",
              component: () =>
                import(
                  "@/view/pages/cms/quotations/quotationCategories/QuotationCategories.vue"
                ),
            },
            {
              path: "/cms/quotations/quotationCategories/:id",
              component: () =>
                import(
                  "@/view/pages/cms/quotations/quotationCategories/QuotationCategories.vue"
                ),
            },
            {
              path: "logos/",
              name: "logos",
              component: () => import("@/view/pages/cms/logos/Logos.vue"),
            },
            {
              path: "/cms/logos/:id",
              component: () => import("@/view/pages/cms/logos/Logos.vue"),
            },
            {
              path: "galleries/",
              name: "gallery",
              component: () =>
                import("@/view/pages/cms/galleries/Galleries.vue"),
            },
            {
              path: "galleries/settings/",
              name: "galleriesSettngs",
              component: () =>
                import("@/view/pages/cms/galleries/Settings.vue"),
            },
            {
              path: "/cms/galleries/:id",
              component: () =>
                import("@/view/pages/cms/galleries/Galleries.vue"),
            },

            {
              path: "externalReferences/",
              name: "externalReferences",
              component: () =>
                import(
                  "@/view/pages/cms/externalReferences/ExternalReferences.vue"
                ),
            },
            {
              path: "externalReferences/settings/",
              name: "externalReferencesSettngs",
              component: () =>
                import("@/view/pages/cms/externalReferences/Settings.vue"),
            },
            {
              path: "/cms/externalReferences/:id",
              component: () =>
                import(
                  "@/view/pages/cms/externalReferences/ExternalReferences.vue"
                ),
            },

            {
              path: "faq/",
              name: "faq",
              component: () => import("@/view/pages/cms/faq/Faqs.vue"),
            },
            {
              path: "faq/settings/",
              name: "faqSettngs",
              component: () => import("@/view/pages/cms/faq/Settings.vue"),
            },
            {
              path: "/cms/faq/:id",
              component: () => import("@/view/pages/cms/faq/Faqs.vue"),
            },
            {
              path: "instructors/",
              name: "instructors",
              component: () =>
                import("@/view/pages/cms/instructors/Instructors.vue"),
            },
            {
              path: "instructors/settings/",
              name: "instructorSettngs",
              component: () =>
                import("@/view/pages/cms/instructors/Settings.vue"),
            },
            {
              path: "/cms/instructors/:id",
              component: () =>
                import("@/view/pages/cms/instructors/Instructors.vue"),
            },

            {
              path: "colleagues/colleagues/",
              name: "colleagues",
              component: () =>
                import("@/view/pages/cms/colleagues/colleagues/Colleagues.vue"),
            },
            {
              path: "/cms/colleagues/colleagues/:id",
              component: () =>
                import("@/view/pages/cms/colleagues/colleagues/Colleagues.vue"),
            },
            {
              path: "colleagues/colleagueCategories/",
              name: "colleagueCategories",
              component: () =>
                import(
                  "@/view/pages/cms/colleagues/colleagueCategories/ColleagueCategories.vue"
                ),
            },
            {
              path: "/cms/colleagues/colleagueCategories/:id",
              component: () =>
                import(
                  "@/view/pages/cms/colleagues/colleagueCategories/ColleagueCategories.vue"
                ),
            },
            {
              path: "colleagues/settings/",
              name: "colleaguesSettngs",
              component: () =>
                import("@/view/pages/cms/colleagues/Settings.vue"),
            },
            {
              path: "banners/banners/",
              name: "banners",
              component: () =>
                import("@/view/pages/cms/banners/banners/Banners.vue"),
            },
            {
              path: "/cms/banners/banners/:id",
              component: () =>
                import("@/view/pages/cms/banners/banners/Banners.vue"),
            },
            {
              path: "banners/bannerTypes/",
              name: "bannerTypes",
              component: () =>
                import("@/view/pages/cms/banners/bannerTypes/BannerTypes.vue"),
            },
            {
              path: "/cms/banners/bannerTypes/:id",
              component: () =>
                import("@/view/pages/cms/banners/bannerTypes/BannerTypes.vue"),
            },
            {
              path: "contactForms/",
              name: "contactForms",
              component: () =>
                import("@/view/pages/cms/contactForms/ContactForms.vue"),
            },
            {
              path: "/cms/contactForms/:id",
              component: () =>
                import("@/view/pages/cms/contactForms/ContactForms.vue"),
            },
            {
              path: "cookieManagement/cookieCategories/",
              name: "cookieCategories",
              component: () =>
                import(
                  "@/view/pages/cms/cookieManagement/categories/CookieCategories.vue"
                ),
            },
            {
              path: "/cms/cookieManagement/cookieCategories/:id",
              component: () =>
                import(
                  "@/view/pages/cms/cookieManagement/categories/CookieCategories.vue"
                ),
            },
            {
              path: "cookieManagement/cookies/",
              name: "cookies",
              component: () =>
                import("@/view/pages/cms/cookieManagement/cookies/Cookies.vue"),
            },
            {
              path: "/cms/cookieManagement/cookies/:id",
              component: () =>
                import("@/view/pages/cms/cookieManagement/cookies/Cookies.vue"),
            },
            {
              path: "media_library/",
              name: "media_library",
              component: () => import("@/view/media_library/Browser.vue"),
            },
            //{ path: '/cms/media_library/:id', component: () => import("@/view/media_library/Browser.vue") },
          ],
        },
        {
          path: "/settings/",
          name: "settings",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "userGroups/",
              name: "userGroups",
              component: () =>
                import("@/view/pages/settings/userGroups/UserGroups.vue"),
            },
            {
              path: "users/",
              name: "users",
              component: () => import("@/view/pages/settings/users/Users.vue"),
            },
            {
              path: "/settings/users/:id",
              component: () => import("@/view/pages/settings/users/Users.vue"),
            },
            {
              path: "currency/",
              name: "currency",
              component: () =>
                import("@/view/pages/settings/currency/Currency.vue"),
            },
            {
              path: "country/",
              name: "country",
              component: () =>
                import("@/view/pages/settings/country/Country.vue"),
            },
            {
              path: "/settings/country/:id",
              component: () =>
                import("@/view/pages/settings/country/Country.vue"),
            },
            {
              path: "roles/",
              name: "roles",
              component: () => import("@/view/pages/settings/roles/Roles.vue"),
            },
            {
              path: "/settings/roles/:id",
              component: () => import("@/view/pages/settings/roles/Roles.vue"),
            },
            {
              path: "/cms/cookieManagement/settings/",
              name: "sites",
              component: () =>
                import("@/view/pages/cms/cookieManagement/Settings.vue"),
            },
          ],
        },
        {
          path: "/companysettings/",
          name: "companySettings",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "departments/",
              name: "departments",
              component: () =>
                import(
                  "@/view/pages/companySettings/departments/Departments.vue"
                ),
            },
            {
              path: "/companysettings/departments/:id",
              component: () =>
                import(
                  "@/view/pages/companySettings/departments/Departments.vue"
                ),
            },
            // {
            //   path: "sites/",
            //   name: "sites",
            //   component: () =>
            //     import("@/view/pages/companySettings/sites/Sites.vue"),
            // },
            // {
            //   path: "/companysettings/sites/:id",
            //   component: () =>
            //     import("@/view/pages/companySettings/sites/Sites.vue"),
            // },
          ],
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
