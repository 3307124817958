export default {
  getTranslated(translations) {
    let appLocale = localStorage.getItem("language");

    if (translations[appLocale]) {
      return translations[appLocale];
    } else {
      return translations[Object.keys(translations)[0]];
    }
  },

  // img2xUrl(val) {
  //   return `${val.replace(/(\.[\w\d_-]+)$/i, '@2x$1')} 2x`;
  // },
};
