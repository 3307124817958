import ApiService from "@/core/services/api.service";

export const FETCH_USER = "fetchUser";
export const USER_COLLECTION = "userCollection";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  [USER_COLLECTION](state) {
    return state.collection;
  },
  getUserByID: (state) => (id) => {
    let item = state.collection.find((item) => item.id == id);
    if (!item) {
      item = { name: "" };
    }
    return item;
  },
};

const actions = {
  [FETCH_USER](context) {
    ApiService.get("user")
      // ApiService.get("contact/contact")
      .then(({ data }) => {
        data.forEach((item) => {
          item.fullName = item.surname + " " + item.christian_name1;
          if (item.christian_name2) item.fullName += " " + item.christian_name2;
        });
        context.commit(FETCH_USER, data);
      })
      .catch((error) => {
        console.log("Error!: ", error);
      });
  },
};

const mutations = {
  [FETCH_USER](state, data) {
    state.collection = data.map((item) => {
      if (!item.status) {
        item.status = 0;
      }
      return item;
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
